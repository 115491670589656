import gql from 'graphql-tag';

export const SETTINGS_GLOBAL = gql`
  query settingsGlobal($locale: String) {
    Settings(locale: $locale) {
      id
      siteName
      email
      socialLabel
      facebook
      instagram
      tripadvisor
      zomato
      privacyLabel
      privacyUrl
      complaintsLabel
      complaintsUrl
      readMoreLabel
    }
  }
`;
