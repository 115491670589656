import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import theme from '../theme';

import { useIsDesktop } from '../lib/hooks';

import monogram from '../assets/monogram-simple.svg';

import Button from './Button';
import Link from './Link';
import Figure from './Figure';
import Label from './Label';
import Paragraph from './Paragraph';
import { QuoteWrap } from './Quote';
import Title from './Title';
import Wrap from './Wrap';

const LAYOUTS = {
  left: 'Left',
  center: 'Center',
  centerSmall: 'CenterSmall',
  imageLeft: 'ImageLeft',
  imageRight: 'ImageRight',
};

const BG_ANIM = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const ArticleWrap = styled(Wrap)`
  align-items: center;
  column-gap: ${theme.sizing.scale400};
  display: grid;
  grid-template-columns: ${({ $layout }) =>
    $layout === LAYOUTS.imageLeft || $layout === LAYOUTS.imageRight
      ? '1fr 1fr'
      : '1fr'};
  position: relative;
  row-gap: ${theme.sizing.scale200};
  text-align: ${({ $layout }) =>
    $layout === LAYOUTS.center || $layout === LAYOUTS.centerSmall
      ? 'center'
      : 'inherit'};

  @media (max-width: ${theme.breakpoints.small}) {
    grid-template-columns: 1fr;
  }

  ${QuoteWrap} + & {
    padding-top: 0;
  }
`;

const Grid = styled.div`
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-name: ${BG_ANIM};
  animation-timing-function: ease-in;
  align-content: center;
  background-image: ${({ $bg }) => ($bg ? `url(${monogram})` : 'none')};
  background-position: center;
  background-repeat: no-repeat;
  align-self: ${({ $layout }) =>
    $layout === LAYOUTS.imageLeft ? 'end' : 'inherit'};
  display: grid;
  justify-items: ${({ $layout }) =>
    $layout === LAYOUTS.center || $layout === LAYOUTS.centerSmall
      ? 'center'
      : 'start'};
  min-height: ${({ $bg }) => ($bg ? '485px' : 0)};
  order: ${({ $layout }) => ($layout === LAYOUTS.imageLeft ? 2 : 'inherit')};
  row-gap: ${({ $bg }) =>
    $bg ? theme.sizing.scale200 : theme.sizing.scale100};

  @media (max-width: ${theme.breakpoints.small}) {
    align-content: start;
    background-image: none;
    justify-items: center;
    min-height: 10px;
    row-gap: ${theme.sizing.scale200};
    text-align: center;
  }

  &::after {
    background-color: ${theme.colors.tertiary};
    bottom: 0;
    content: '';
    display: ${({ $layout }) =>
      $layout === LAYOUTS.imageLeft ? 'block' : 'none'};
    height: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;

    @media (max-width: ${theme.breakpoints.small}) {
      height: calc(100% - ${theme.sizing.scale700});
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${({ $spacer }) =>
    $spacer ? theme.sizing.scale400 : theme.sizing.scale100};

  @media (max-width: ${theme.breakpoints.small}) {
    justify-self: center;
    margin-top: 0;
  }
`;

const StyledFigure = styled(Figure)`
  margin-left: ${({ $layout }) => ($layout === LAYOUTS.left ? 'auto' : 0)};
  margin-top: ${({ $layout }) =>
    $layout === LAYOUTS.left ? `-${theme.sizing.scale600}` : 0};
  transform: ${({ $layout }) =>
    $layout === LAYOUTS.imageLeft ? 'scale(1.1)' : 'none'};
  transform-origin: 0 bottom;
  z-index: 1;

  @media (max-width: ${theme.breakpoints.small}) {
    margin: 0;
    order: ${({ $layout }) =>
      $layout === LAYOUTS.imageLeft ? '-1' : 'inherit'};
    transform: none;
    width: 70vw;
  }
`;

const StyledLabel = styled(Label)`
  ${ArticleWrap}:first-child & {
    @media (min-width: ${theme.breakpoints.small}) {
      display: none;
    }
  }

  @media (max-width: ${theme.breakpoints.small}) {
    margin-bottom: ${({ layout }) =>
      layout === LAYOUTS.imageLeft ? `-${theme.sizing.scale200}` : 0};
    margin-top: ${({ layout }) =>
      layout === LAYOUTS.imageLeft ? theme.sizing.scale100 : 0};
  }
`;

const Article = ({ link, image, label, layout, subTitle, title, text }) => {
  const isDesktop = useIsDesktop();

  const isRow =
    layout !== LAYOUTS.imageLeft &&
    layout !== LAYOUTS.imageRight &&
    layout !== LAYOUTS.centerSmall;

  return (
    <ArticleWrap
      $layout={layout}
      large={layout !== LAYOUTS.centerSmall}
      spacer={layout !== LAYOUTS.imageLeft}
    >
      <Grid $layout={layout} $bg={isRow}>
        {label && <StyledLabel layout={layout}>{label}</StyledLabel>}
        {title && <Title>{title}</Title>}
        {image && !isDesktop && (
          <StyledFigure image={image} $layout={layout} clip />
        )}
        {subTitle && <Title size="small">{subTitle}</Title>}
        {text && (
          <Paragraph
            content={text}
            columns={layout === LAYOUTS.left ? 2 : 1}
            delay={800}
          />
        )}
        {link &&
          link.map((item, i) => (
            <StyledButton key={i} as={Link} $spacer={isRow} {...item} />
          ))}
      </Grid>
      {image && isDesktop && (
        <StyledFigure image={image} $layout={layout} clip />
      )}
    </ArticleWrap>
  );
};

Article.propTypes = {
  image: PropTypes.string,
  label: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(LAYOUTS)).isRequired,
  link: PropTypes.arrayOf(PropTypes.object),
  subTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default memo(Article);
