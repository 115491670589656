import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';

import theme from '../theme';

import { useIntersectionObserver } from '../lib/hooks';

import scroll from '../assets/scroll.svg';

import Figure from './Figure';
import Wrap from './Wrap';
import Label from './Label';
import Button from './Button';
import Link from './Link';
import Monogram from './Monogram';
import Paragraph from './Paragraph';
import Title from './Title';

const SIZES = {
  regular: 'Regular',
  tall: 'Tall'
};

export const HeroWrap = styled.section`
  align-items: center;
  background-color: ${({ light }) =>
    light ? theme.colors.tertiary : 'transparent'};
  color: ${({ light }) => (light ? theme.colors.default : '#fff')};
  display: flex;
  min-height: ${({ size }) => (size === SIZES.tall ? 800 : 580)}px;
  transform-style: preserve-3d;

  &:first-child {
    min-height: 100vh;
    margin-top: -${theme.sizing.scale500};

    @media (max-width: ${theme.breakpoints.small}) {
      margin-top: -${theme.sizing.scale600};
    }
  }
`;

const StyledWrap = styled(Wrap)`
  align-content: center;
  display: grid;
  row-gap: ${theme.sizing.scale200};
  justify-items: center;
  text-align: center;
  transform: translateZ(10px);
`;

const Styledfigure = styled(Figure)`
  background-color: #060e1a;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  img {
    opacity: 0.75;
  }
`;

const ScrollIndicator = styled(animated.button)`
  background-color: transparent;
  bottom: ${theme.sizing.scale200};
  display: inline-grid;
  font-family: ${theme.typography.families.title};
  font-size: ${theme.typography.font200};
  font-weight: ${theme.typography.weights.bold};
  letter-spacing: ${2 / theme.typography.baseFontSize}rem;
  left: 50%;
  position: absolute;
  transform: translate3d(-50%, 0, 10px);
  transition: color ${theme.transitions.duration} ${theme.transitions.easing};
  user-select: none;

  &:hover,
  &:focus {
    color: ${theme.colors.quaternary};
    outline: 0;
  }
`;

const ScrollLabel = styled.span`
  display: grid;
  line-height: 1;
  margin: 0 auto;
  padding-left: 0.5em;
  transform: scale(-1);
  writing-mode: vertical-rl;
`;

const { fadeIn } = theme.animations;

const Hero = ({ button, image, label, order, size, title, text }) => {
  const sectionRef = useRef();
  const active = useIntersectionObserver(sectionRef);
  const anim = useSpring({
    from: fadeIn.from,
    to: active ? fadeIn.to : fadeIn.from,
    config: config.slow
  });
  const isFirst = order === 0;
  const isLight = !image;

  return (
    <HeroWrap ref={sectionRef} light={isLight} size={size}>
      {image && <Styledfigure image={image} cover />}
      <StyledWrap>
        {label ? <Label light={!isLight}>{label}</Label> : <Monogram />}
        <Title size="large">{title}</Title>
        {text && <Paragraph content={text} alt />}
        {button && (
          button.map((item, i) => (
            <Button key={i} as={Link} {...item} style={anim} />
          ))
        )}
      </StyledWrap>
      {isFirst && (
        <ScrollIndicator
          style={anim}
          onClick={() => window.scroll(0, sectionRef.current.offsetHeight)}
        >
          <ScrollLabel>scroll</ScrollLabel>
          <img src={scroll} alt="" />
        </ScrollIndicator>
      )}
    </HeroWrap>
  );
};

Hero.propTypes = {
  button: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  image: PropTypes.string,
  order: PropTypes.number,
  size: PropTypes.oneOf(Object.values(SIZES)).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default memo(Hero);
